@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700&display=swap');



html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}



* {
  box-sizing: border-box;
  outline: none;
}


input[type=number] -webkit-inner-spin-button,
input[type=number] -webkit-outer-spin-button {
  font-family: "Poppins", sans-serif;
}

body, input, textarea, button, p, a, li, select,
h1, h2, h3, h4, h5, h6 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #000000;
}

.container {
  width: 95%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  padding: 0 20px;
}
@media (max-width: 1024px) {
  .container {
    width: 98%;
  }
}

.container-fluid {
  width: 100%;
}

.row {
  margin: 0;
}










body {font-family: 'Nunito', sans-serif !important; overflow-x: hidden; }
body,html{background-color: #fff !important;}
img {width: 100%;}
html, body { margin: 0; padding: 0;}
* { box-sizing: border-box;}

/* modal css code starts */
.modal-content {background-color: #ebebeb !important; padding: 25px 50px !important;}
.close_modal_btn {background-color: #f77727 !important;opacity: 1 !important; color: #fff !important; width: 50px !important; height: 50px !important; border-radius: 50% !important; position: absolute; right: -25px; top: -25px;}
.heading_modal {margin-bottom: 10px;}
.heading_modal h2, .modal_form_content h2 {font-size: 24px; font-weight: 700;}
.heading_modal h6 { font-size: 15px;}
.modal_form_content input {height: 45px; position: relative;padding-left: 40px;}
.forgot_psw_link a { color: #735ac2; font-weight: 400;}
.forgot_psw_link a:hover { color: #3596e0;}
.orange-login-btn {margin: 20px 0px 0; width: 100%;height: 45px;border: 1px solid transparent;background-color: #f77727; color: #fff; border-radius: 7px; cursor: pointer;}
.line_through {border: 1px solid #bcbcbc; max-width: 235px; margin: 0 auto;}
.or_text {text-align: center; color: #757575; position: absolute; top: -12px; left: 0px; right: 0px; background-color: #ebebeb; max-width: 140px; margin: 0 auto;}
.social_login_btn {background-color: #fff; border: 1px solid transparent; padding: 10px 20px; min-width: 150px; box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%); width: 48%; margin-top: 40px;}
.social_login_block {display: flex; justify-content: center;}
.signup_btn { padding: 15px 20px; width: 100%; cursor: pointer; border: 0; color: #272727; font-weight: 400; background-color: #fff; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;}
.signup_btn span {color: #735ac2; font-weight: 600; margin-left: 0px;}
.signup_btn span:hover {color: #3596e0;}
.social_login_block button img {width: 25px; margin-right: 10px;}
.modal_form_content .form-group {position: relative;}
.modal_form_content .form-group i {position: absolute; top: 15px; z-index: 1; left: 12px; color: #7b7b7b;}
.ct-dropdown {width: 100% !important; background-color: #fff !important; color: grey !important;text-align: left !important; padding-left: 40px !important; border: 1px solid #dbdfe4 !important;}
.ct-dropdown i {top: 11px !important;}
.ct-dropdown::after {position: absolute; right: 20px; top: 15px;}
.fp .orange-login-btn {margin: 25px 0px 0px 0px !important;}
.orange_text {color: #f77727;}
.resent-link {cursor: pointer; color: #8a8a8a !important;}
.link-sent .modal-content {background-color: #fff !important;}
.link-sent .orange-login-btn {margin: 20px 0px;}
.green-tick {width: 50px; margin: 10px auto;}



/* loader Message popup  css  */
#snackbar {visibility: hidden;max-width: 280px;margin-left: -125px;color: #fff; border-radius: 2px; padding: 10px 10px 10px 15px; position: fixed; z-index: 100000; top: 20%; right: 20px; font-size: 14px; opacity: 0; font-weight: 600; transform: scale(0.8); transition: all 0.3s !important; -moz-transition: all 0.3s !important; -webkit-transition: all 0.3s !important; display: flex; align-items: center;}
#snackbar.show {visibility: visible; opacity: 1; top: 20%; transform: scale(1);}
#snackbar button {min-width: 30px !important; width: 30px !important; height: 30px !important; columns: #fff !important; border-radius: 50% !important;}
#snackbar button svg {font-size: 18px !important;}
.txt-red {color: #ffd492!important;}
a.txt-red:hover {color: #fff !important;}
.border-effect {position: relative; color: #fff; text-decoration: none;}
.border-effect:after {display: block; position: absolute; left: 0; bottom: -2px; width: 0; height: 1px; background-color: #fff; content: ""; -webkit-transition: all 0.2s; -moz-transition: all 0.2s; -o-transition: all 0.2s; transition: all 0.2s;}
.border-effect:hover {text-decoration: none; color: #fff;}
.border-effect:hover:after {width: 100%;}
.text-black {color: #000 !important;}
.link {color: rgba(255, 255, 255, 0.7);}
.link:hover {color: rgba(255, 255, 255, 0.9);}

.btn {height: auto !important;}
.bg-red {background-image: linear-gradient(#7b5616, #c98927); color: #fff !important;}
a:hover {text-decoration: none;}
.btn-red span {background-image: none !important; box-shadow: none !important; text-shadow: none !important;}
.btn-round {border-radius: 25px !important;}
.btn {font-size: 14px; color: #fff; border-radius: 0px; text-transform: uppercase; outline: none !important; box-shadow: none !important; transition: all 0.2s !important; font-weight: 500; border-radius: 4px; position: relative; overflow: hidden; z-index: 0;}
.btn:after {background: #fff; content: ""; height: 155px; left: -75px; opacity: .1; position: absolute; top: -50px; -webkit-transform: rotate(35deg); -ms-transform: rotate(35deg); transform: rotate(35deg); -webkit-transition: all 1.2s cubic-bezier(.19, 1, .22, 1);-o-transition: all 1.2s cubic-bezier(.19, 1, .22, 1);transition: all 1.2s cubic-bezier(.19, 1, .22, 1); width: 50px; z-index: -1; display: none !important;}
.btn:hover:after, button:hover:after {left: 120%;}
.btn-lg {font-size: 14px !important; padding: 8px 25px !important; line-height: 35px !important;}
.btn-red { background: linear-gradient( 90deg, #f4a430 0%, #c98927 90%) !important; color: #fff !important; border: 1px solid #c98927 !important;}
.btn-red:hover {background: linear-gradient( 90deg, #c98927 0%, #f4a430 90%) !important; color: #fff !important;}
.btn-border {border: 1px solid rgba(255, 255, 255, 0.3) !important; color: #fff !important; background: none !important;}
.btn-border:hover {background: rgba(255, 255, 255, 0.050) !important;}































/*----login css start here----*/
.lgn_title_Hdng,
.login_page_wrapper,
.logo,
.logo a,
.logo a img,
.Logn_wppr,
.login_signup,
.heading_modal,
.modal_form_content,
.inputbox,
.Rmbr-wppr {
  width: 100%;
  display: block;
  position: relative;
}

input:focus,
.form-control:focus {
  box-shadow: none;
}

.rspnsv-img {
  display: none;
}
@media (max-width: 991px) {
  .rspnsv-img {
    display: block;
    width: 100%;
  }
}

.heardr-rmvo div#root header,
.heardr-rmvo div#root footer {
  display: none;
}

.login_page_wrapper {
  padding: 50px 0;
}
@media (max-width: 991px) {
  .login_page_wrapper {
    height: auto !important;
    padding: 0;
  }
  .login_page_wrapper .col-12,
  .login_page_wrapper .container {
    width: 100%;
    padding: 0;
    height: auto !important;
  }
}
.login_page_wrapper::before {
  width: auto;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  content: "";
  background: url(../images/login/login_bg.png) left center no-repeat;
  background-size: 60%;
}
@media (max-width: 1240px) {
  .login_page_wrapper::before {
    background-size: 100%;
    background: url(../images/login/login_bg.png) -5% center no-repeat;
    background-size: contain;
    left: -25px;
  }
}
@media (max-width: 1140px) {
  .login_page_wrapper::before {
    background: url(../images/login/login_bg.png) -90% center no-repeat;
    background-size: contain;
    left: 0;
  }
}
@media (max-width: 1024px) {
  .login_page_wrapper::before {
    background: url(../images/login/login_bg.png) -70% center no-repeat;
    background-size: contain;
  }
}
@media (max-width: 991px) {
  .login_page_wrapper::before {
    display: none;
  }
}
.login_page_wrapper .container {
  width: 100%;
}

.lgn_title_Hdng {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 79vh !important;
}
@media (max-width: 991px) {
  .lgn_title_Hdng {
    justify-content: center;
    position: absolute;
    width: 100%;
    padding: 50px 0;
    height: 100% !important;
    top: 0;
    left: 0;
  }
}
@media (max-width: 768px) {
  .lgn_title_Hdng {
    padding: 30px 0;
  }
}
.lgn_title_Hdng .logo {
  width: 150px;
  margin-left: 30px;
}
@media (max-width: 768px) {
  .lgn_title_Hdng .logo {
    margin-left: 0px;
  }
}
@media (max-width: 991px) {
  .lgn_title_Hdng .title {
    margin: 0px 0;
    padding: 0 15px;
  }
}
.lgn_title_Hdng .title h1 {
  color: #ffffff;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 1px;
  padding-left: 30px;
}
@media (max-width: 1024px) {
  .lgn_title_Hdng .title h1 {
    line-height: 32px;
    line-height: 35px;
    text-align: left;
    font-size: 28px;
  }
}
@media (max-width: 991px) {
  .lgn_title_Hdng .title h1 {
    font-size: 20px;
    line-height: 26px;
    margin-left: auto;
    text-align: center;
    padding: 0;
  }
  .lgn_title_Hdng .title h1 em {
    font-weight: 400;
  }
}

.Logn_wppr {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 50px;
}
@media (max-width: 1240px) {
  .Logn_wppr {
    padding-right: 0;
  }
}
@media (max-width: 991px) {
  .Logn_wppr {
    justify-content: center;
    padding: 50px 0;
  }
}
@media (max-width: 768px) {
  .Logn_wppr {
    padding: 0px 0 20px;
  }
}
.Logn_wppr .login_signup {
  width: 50%;
}
@media (max-width: 1600px) {
  .Logn_wppr .login_signup {
    width: 60%;
  }
}
@media (max-width: 1140px) {
  .Logn_wppr .login_signup {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
  }
}
@media (max-width: 860px) {
  .Logn_wppr .login_signup {
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .Logn_wppr .login_signup {
    padding-top: 20px;
    width: 60%;
    padding: 0 15px;
  }
  .Logn_wppr .login_signup div {
    width: 100%;
  }
}
@media (max-width: 675px) {
  .Logn_wppr .login_signup {
    width: 100%;
  }
}
.Logn_wppr .login_signup .heading_modal h2,
.Logn_wppr .login_signup .SgnUp_hdng_Wppr h2 {
  text-align: right;
  color: #df822f;
  font-weight: 400;
  margin-left: auto;
}
@media (max-width: 991px) {
  .Logn_wppr .login_signup .Sgnup_hdng,
  .Logn_wppr .login_signup .heading_modal {
    width: 100% !important;
  }
}
.Logn_wppr .login_signup .Sgnup_hdng h2,
.Logn_wppr .login_signup .heading_modal h2 {
  line-height: 45px;
  font-size: 38px;
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  .Logn_wppr .login_signup .Sgnup_hdng h2,
  .Logn_wppr .login_signup .heading_modal h2 {
    font-size: 24px;
    line-height: 30px;
  }
}
.Logn_wppr .login_signup .bck_btn {
  cursor: pointer;
}
@media (max-width: 768px) {
  .Logn_wppr .login_signup .bck_btn {
    width: auto;
  }
}
.Logn_wppr .login_signup .bck_btn img {
  width: 60px;
  display: block;
}
.Logn_wppr .login_signup .bck_btn:hover {
  color: #e58e31;
}
.Logn_wppr .login_signup .modal_form_content {
  z-index: 1;
}
@media (max-width: 991px) {
  .Logn_wppr .login_signup .modal_form_content {
    width: auto;
  }
}
.Logn_wppr .login_signup .modal_form_content .entr-btn {
  margin-bottom: 8px;
  position: relative;
}
.Logn_wppr .login_signup .modal_form_content .signUp_otp .otp_box {
  background: #e2e0e5;
  padding: 5px;
}
.Logn_wppr .login_signup .modal_form_content .signUp_otp .otp_box input {
  width: 35px;
  height: 35px;
  border: 0;
  border-color: none;
  padding: 0;
  font-size: 24px;
  text-align: center;
}
.Logn_wppr .login_signup .modal_form_content .signUp_otp .otp_dur .resendOTP {
  font-size: 14px;
  color: #eaa33b;
  font-weight: 700;
  cursor: pointer;
}
.Logn_wppr .login_signup .modal_form_content .signUp_otp .otp_dur .resendOTP:hover {
  color: #e58e31;
}
.Logn_wppr .login_signup .modal_form_content .inputbox {
  margin-bottom: 8px;
}
.Logn_wppr .login_signup .modal_form_content .inputbox .input-field {
  width: 100%;
  border: 0;
  background: #e2e0e5;
  border-radius: 0;
  font-size: 16px;
  line-height: 24px;
  padding-left: 46px;
}
.Logn_wppr .login_signup .modal_form_content .inputbox .input-field::-moz-placeholder {
  font-weight: 500;
  font-size: 14px;
}
.Logn_wppr .login_signup .modal_form_content .inputbox .input-field::placeholder {
  font-weight: 500;
  font-size: 14px;
}
.Logn_wppr .login_signup .modal_form_content .inputbox select.form-control.input-field {
  font-size: 14px;
  height: 45px;
  padding-left: 0 !important;
  margin-left: -3px;
}
.Logn_wppr .login_signup .modal_form_content .inputbox .icon {
  position: absolute;
  left: 13px;
  z-index: 1;
  top: 50%;
  width: 20px;
  -o-object-fit: contain;
     object-fit: contain;
  height: 18px !important;
  transform: translateY(-50%);
}
.Logn_wppr .login_signup .modal_form_content .inputbox #edit_email {
  display: none;
  position: absolute;
  background: #eaa33b;
  padding: 5px 8px;
  font-size: 12px;
  font-weight: 700;
  right: 5px;
  z-index: 1;
  cursor: pointer;
  color: #ffffff;
  top: 6px;
}
.Logn_wppr .login_signup .modal_form_content .inputbox #edit_email:hover {
  background: #e58e31;
}
.Logn_wppr .login_signup .modal_form_content .inputbox #edit_email::after {
  display: none;
}
.Logn_wppr .login_signup .modal_form_content .slct-bx {
  background: #e2e0e5;
  padding-left: 46px;
}
.Logn_wppr .login_signup .modal_form_content .buttons .orange-login-btn {
  border-radius: 0;
  font-size: 26px;
  background: #eaa33b;
  margin-bottom: 5px;
  margin-top: 8px;
}
.Logn_wppr .login_signup .modal_form_content .buttons .orange-login-btn:hover {
  background: #e58e31;
}
@media (max-width: 991px) {
  .Logn_wppr .login_signup .modal_form_content .buttons .orange-login-btn {
    font-size: 20px;
  }
}
.Logn_wppr .login_signup .modal_form_content .Hlp_Btn {
  float: left;
}
@media (max-width: 768px) {
  .Logn_wppr .login_signup .modal_form_content .Hlp_Btn {
    width: auto;
  }
}
.Logn_wppr .login_signup .modal_form_content .mor_btn {
  float: right;
}
@media (max-width: 768px) {
  .Logn_wppr .login_signup .modal_form_content .mor_btn {
    width: auto;
  }
}
.Logn_wppr .login_signup .modal_form_content .otp-wppr {
  transition: all 0.5s ease-in-out;
  width: 100%;
  display: block;
  position: relative;
  opacity: 0.5;
}
.Logn_wppr .login_signup .modal_form_content .otp-wppr::before {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  content: "";
  background: translate;
  cursor: no-drop;
  z-index: 1;
}
.Logn_wppr .login_signup .modal_form_content .otp-wppr p,
.Logn_wppr .login_signup .modal_form_content .otp-wppr span {
  font-weight: 500;
}
.Logn_wppr .login_signup .modal_form_content .otp-wppr .form-group .input-field {
  padding: 5px;
  padding-left: 46px;
}
.Logn_wppr .login_signup .modal_form_content .otp-wppr .form-group .input-field .form-control {
  width: 35px;
  padding: 3px;
  height: 35px;
  text-align: center;
  font-size: 24px;
  color: #a2a1a3;
}
.Logn_wppr .login_signup .modal_form_content .otp-wppr .form-group .resendOTP {
  font-weight: 600;
  color: #eaa33b;
  cursor: pointer;
  font-size: 14px;
}
.Logn_wppr .login_signup .modal_form_content .otpentr {
  opacity: 1;
}
.Logn_wppr .login_signup .modal_form_content .otpentr::before {
  display: none;
}
.Logn_wppr .login_signup .modal_form_content .forgot_psw_link a,
.Logn_wppr .login_signup .modal_form_content .Hlp_Btn a,
.Logn_wppr .login_signup .modal_form_content .mor_btn a {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
  opacity: 0.7;
}
.Logn_wppr .login_signup .modal_form_content .forgot_psw_link a:hover,
.Logn_wppr .login_signup .modal_form_content .Hlp_Btn a:hover,
.Logn_wppr .login_signup .modal_form_content .mor_btn a:hover {
  color: #e58e31;
  opacity: 1;
}
.Logn_wppr .login_signup .modal_form_content .Rmbr-wppr {
  float: left;
  margin: 20px 0;
}
@media (max-width: 768px) {
  .Logn_wppr .login_signup .modal_form_content .Rmbr-wppr {
    margin: 10px 0 15px;
  }
}
.Logn_wppr .login_signup .modal_form_content .Rmbr-wppr .Rmbr-txt-Frm-Wppr {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.Logn_wppr .login_signup .modal_form_content .Rmbr-wppr .Rmbr-txt-Frm-Wppr .chckbx_btn {
  position: absolute;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.Logn_wppr .login_signup .modal_form_content .Rmbr-wppr .Rmbr-txt-Frm-Wppr .chckbx_btn:hover ~ label {
  background: #eaa33b;
}
.Logn_wppr .login_signup .modal_form_content .Rmbr-wppr .Rmbr-txt-Frm-Wppr .chckbx_btn:checked ~ label {
  background: #eaa33b;
  border-color: #eaa33b;
}
.Logn_wppr .login_signup .modal_form_content .Rmbr-wppr .Rmbr-txt-Frm-Wppr .chckbx_btn:checked ~ label::before {
  display: block;
}
.Logn_wppr .login_signup .modal_form_content .Rmbr-wppr .Rmbr-txt-Frm-Wppr label {
  margin-right: 10px;
  width: 15px;
  height: 15px;
  border: 1px solid #666;
  position: relative;
}
.Logn_wppr .login_signup .modal_form_content .Rmbr-wppr .Rmbr-txt-Frm-Wppr label::before {
  width: 60%;
  height: 5px;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 50%;
  position: absolute;
  content: "";
  transform: translate(-50%, -76%) rotate(-36deg);
  border-left: 2px solid #e2e0e5;
  border-bottom: 2px solid #e2e0e5;
  display: none;
}
.Logn_wppr .login_signup .modal_form_content p {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
  opacity: 0.7;
}
@media (max-width: 768px) {
  .Logn_wppr .login_signup .modal_form_content p {
    padding-left: 0 !important;
  }
}
.Logn_wppr .login_signup .signup_btn {
  background: #eaa33b;
  border-radius: 0;
  color: #ffffff;
  padding: 5px 0;
  font-size: 22px;
  line-height: 35px;
  font-weight: 400;
}
@media (max-width: 1240px) {
  .Logn_wppr .login_signup .signup_btn {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .Logn_wppr .login_signup .signup_btn {
    padding: 5px;
    font-size: 17px;
  }
}
.Logn_wppr .login_signup .signup_btn span {
  color: #f5d76a;
  margin-left: 5px;
  font-weight: 400;
}
.Logn_wppr .login_signup .signup_btn span:hover {
  color: #666;
}

/* device restriction css code start*/
.connectedDevicesPopover{position: absolute; top:97px; left: 0px; width: 100%; height: auto; z-index: 100; background: #fff; border-radius: 7px; }
.connectedDevicesPopover .head{padding: 25px 50px; border-bottom: 1px solid #e7e7e7;}
.connectedDevicesPopover .head h4{font-weight: 500; font-size: 20px; margin-bottom: 5px;}
.connectedDevicesPopover .head p{font-size: 13px; font-weight: 500; opacity: 0.7;}
.connectedDevicesPopover .head h3{color: #e39a34; font-weight: 400; font-size: 22px;}
.text-y{color:#e39a34 ;}


.connectedDevicesPopover .scroll{max-height: 266px; overflow: scroll;}
.connectedDevicesPopover .data{padding: 17px 50px; border-bottom: 1px solid #e7e7e7;}
.connectedDevicesPopover .data:hover{background: #fafafa;}
.connectedDevicesPopover .data h4{font-weight: 500; font-size: 20px; margin-bottom: 5px;}
.connectedDevicesPopover .data p{font-size: 13px; font-weight: 500; opacity: 0.7;}


.connectedDevicesPopover .data{ border-bottom: 1px solid #e7e7e7;}
.connectedDevicesPopover .data:last-child{border-bottom: 0px;}
.connectedDevicesPopover .data .w-75 h3{font-size: 16px; font-weight: 500; color: #121212; margin-bottom: 5px;}
.connectedDevicesPopover .data .w-75 span{font-size: 18px; font-weight: 500; color: #6e6e6e; opacity: 0.8;}
.connectedDevicesPopover .data button{font-weight:600 !important ; font-size: 14px; text-transform: capitalize; padding: 3px 20px !important; border: 2px solid #ffe65b !important; border-radius: 10px !important; color: rgb(98, 98, 98) !important;}


.connectedDevicesPopover .close_{min-width: 35px !important; height: 35px !important; width: 35px !important; border-radius: 50% !important; position: absolute; top: 15px; right: 15px; z-index: 100;}

.connectedDevicesPopover .close_ svg{font-size: 25px !important; color: #e39a34; transition: all 0.2s ease-in-out;}
.connectedDevicesPopover .close_:hover svg{color: #000;}

@media (min-width:240px) and (max-width: 575px) {
    .connectedDevicesPopover .head, .connectedDevicesPopover .data{padding: 20px 25px !important;}
    .connectedDevicesPopover .w-75{padding-bottom: 20px;  width: 100% !important;}
    .connectedDevicesPopover .w-75 h3{font-size: 20px;}
    .connectedDevicesPopover.w-75, .connectedDevicesPopover .w-25{max-width: 100% !important; width: 100% !important;}
    .connectedDevicesPopover .data{flex-direction: column;}
}
/* device restriction css code end*/

/*------login css ends here--------*/