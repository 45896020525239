/* .carouselWrapper{min-height: 70vh;} */
.placeholder div {
  display: block !important;
}

.bnnr-sldr .placeholder {
  border-radius: 0 !important;
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-collapse {
    display: block !important;
  }
}

@media (min-width: 1400px) {
  .home_banner .item img {
    height: 700px !important;
  }
}

@media (min-width:240px) and (max-width: 1200px) {
  .res-full {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }

  .res-full:nth-child(1) {
    margin-bottom: 15px;
  }

  .vdeo-wppr .col-12 {
    height: auto !important;
  }

  .vdo_sctn_wppr .vdeo-wppr {
    flex-wrap: inherit !important;
    position: relative;
    z-index: 8;
  }

  .prt-1 {
    order: 2
  }

  .prt-2 {
    order: 1;
    margin-bottom: 5px;
  }

  .videoplayerwrapper {
    position: absolute;
    padding-bottom: 40%;
  }

  .videoplayerwrapper video {
    position: absolute;
  }

  .carouselWrapper {
    min-height: auto !important;
    height: auto !important;
  }


  footer .part2 {
    justify-content: space-between !important;
  }

  .playerNewControls .videoControls .playercontrolls__next_prev_wrapper {
    top: 0px !important;
  }

  .dsbrd-hdng-wppr {
    padding: 25px !important;
  }


  .vdo_sctn_wppr .videoplayerwrapper {
    position: relative !important;
    padding-bottom: 0px !important;
    margin-bottom: 10px;
  }

}


@media (min-width:240px) and (max-width: 1100px) {
  .carouselWrapper {
    min-height: auto !important;
    height: auto !important;
  }

  .owl-carousel.bnnr-sldr.owl-loaded .vido-wppr::before {
    display: none !important;
  }

  .thumb_slider .slick-trac,
  .tabbing-wppr .thmmls-wppr.searchResult {
    width: 100% !important;
    display: block !important;
    white-space: nowrap !important;
    overflow-x: scroll !important;
    overflow-y: hidden;
    transform: inherit !important;
  }

  .thumb_slider .slick-track::-webkit-scrollbar,
  .tabbing-wppr .thmmls-wppr.searchResult::-webkit-scrollbar {
    display: none !important;
  }

  .thumb_slider .slick-track .slick-slide,
  .tabbing-wppr .tbng-wppr-bx .vdo-tbng-sldr-wppr .thm_slr_bx div.thmls-itm {
    float: none !important;
    display: inline-block !important;
    opacity: 1 !important;
    width: 200px !important;
  }



  .tabbing-wppr .thmmls-wppr.searchResult .col-md-12 {
    display: inline-block !important;
  }

  .thumb_slider .slick-track .slick-slide,
  .tabbing-wppr .thmmls-wppr.searchResult .col-md-12 {
    width: 175px !important;
  }

  .thm_sldr.squr_sldr .slick-track .slick-slide {
    width: 155px !important;
  }

  .thumb_slider .slick-track .slick-slide * {
    white-space: normal !important;
  }

  .thmmls-wppr.searchResult .col-12,
  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr.searchResult .col-12 {
    display: inline-block !important;
    padding: 0px !important;
    margin-right: 8px !important;
  }

  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12:nth-child(2),
  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12:nth-child(4),
  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12:nth-child(6),
  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12:nth-child(8),
  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12:nth-child(10) {
    margin-right: 8px !important;
  }

  .slick-active button.dlt-btn {
    background: none !important;
  }


  .vdo_sctn_wppr .vdeo-wppr .col-lg-7 {
    padding-right: 0px !important;
  }

  .thm_hdng_wppr a {
    white-space: nowrap !important;
  }

}

@media (min-width: 1100px) and (max-width: 1300px) {
  .owl-carousel.bnnr-sldr.owl-loaded .vido-wppr {
    height: auto !important;
  }

  .plans-page div.part1{padding: 50px 16px !important;}
  .plans-page .part1 .order1{text-align: right !important;}
  .plans-page .part1 .arrowIcon{width: 170px !important;}

  .bnnr-sldr .placeholder img,
  .bnnr-sldr .player {
    height: 60vh !important;
  }

  .carouselWrapper {
    height: auto !important;
  }

  .dsbrd-hdng-wppr {
    padding: 25px !important;
  }

  .vdo-txt-wppr .vdo-pera-wppr {
    max-height: 40vh;
  }


  .plans-page .part2 .plansList .col2 span.span_::before{top: 4px !important;}
  .plans-page div.part2{padding: 0px 10px !important;}

}




@media (min-width:450px) and (max-width:992px) {
  .vdo_sctn_wppr .vdeo-wppr .crt-btn-wppr .crt-btn-bx {
    width: 160px !important;
  }
}


@media (min-width:450px) and (max-width:525px) {
  .download_app_section_header{position: relative; z-index: 1000;}
  .rspnsv-img{margin-top: -100px;}
  .lgn_title_Hdng{height: 70% !important;}
}

@media (min-width:525px) and (max-width:600px) {
  .download_app_section_header{position: relative; z-index: 1000;}
  .rspnsv-img{margin-top: -150px;}
  .lgn_title_Hdng{height: 70% !important;}
}

@media (min-width:600px) and (max-width:767px) {
  .download_app_section_header{position: relative; z-index: 1000;}
  .rspnsv-img{margin-top: -300px;}
  .lgn_title_Hdng{height: 70% !important;}
}


@media (min-width:767px) and (max-width:992px) {
  .download_app_section_header{position: relative; z-index: 1000;}
  .rspnsv-img{margin-top: -300px;}
  .lgn_title_Hdng{height: 70% !important;}
}




@media (min-width: 240px) and (max-width:575px) {
  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12,.mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12 {
    margin-right: 5px;
    margin-bottom: 5px;
    flex: 0 0 46.7% !important;
    max-width: 46.7% !important;
  }

  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12:nth-child(2),
  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12:nth-child(4),
  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12:nth-child(6),
  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12:nth-child(8),
  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12:nth-child(10) {
    margin-right: 0px;
  }
  .viewAllBtn{font-size: 14px !important;}

  .placeholder.hor .lazyload-placeholder {
    height: 10vh !important;
  }

  .hdng-txt-wppr li a.ml-2 {
    margin-left: 0px !important;
  }

  .pymt-plan-wppr .col-md-4 {
    display: inline-block !important;
    width: 235px;
    vertical-align: top;
  }

  .payment .payment-bx-wppr .pymt-plan-wppr .pln-wppr .pln-hdng-wppr .title p {
    font-size: 16px !important;
  }

  .vdo-popbx .newsticker {
    zoom: 70% !important;
  }

  .vdo_sctn_wppr .vdeo-wppr .vdo-txt-wppr .title p {
    font-size: 13px !important;
    line-height: 22px !important;
  }

  .thumb_slider .slick-track .slick-slide {
    width: 150px;
  }


  .titleHd{margin-bottom: 15px !important;}

}


@media (min-width: 240px) and (max-width:450px) {
  .vdo_sctn_wppr .vdeo-wppr .crt-btn-wppr .crt-btn-bx {
    width: 100% !important;
    zoom: 80%;
  }

  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-right: 0px;
  }

  .thmmls-wppr.searchResult .col-12,
  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr.searchResult .col-12 {
    flex: 0 0 46.7% !important;
    max-width: 46.7% !important;
    display: inline-block !important;
  }



}

@media (min-width: 575px) and (max-width:767px) {
  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12,.mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12 {
    max-width: 32.3% !important;
    margin-right: 8px;
    margin-bottom: 5px;
  }

  .myListPage.thmmls-wppr .col-12 {
    min-height: 170px !important;
  }

  .pymt-plan-wppr .col-md-4 {
    display: inline-block !important;
    width: 270px;
    vertical-align: top;
  }

  .thumb_slider .slick-track .slick-slide {
    width: 30% !important;
  }

  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12:nth-child(3),
  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12:nth-child(6),
  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12:nth-child(9),
  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12:nth-child(12),
  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12:nth-child(15) {
    margin-right: 0px !important;
  }

  .thumb_slider .slick-track .slick-slide {
    width: 220px !important;
  }

  .thumb_slider.vrtcl_sldr-2 .slick-track .slick-slide {
    width: 150px !important;
  }
}


@media (min-width: 240px) and (max-width: 767px) {
  .thm_sldr .owl-item {
    transition: all 0.0s !important;
  }
  
  .Logn_wppr .login_signup .modal_form_content{padding-bottom: 30px !important;}
  .vdo-pera-wppr {
    max-height: inherit;
    overflow: hidden;
    overflow-x: hidden;
    height: auto;
  }

  .dlt-btn {
    position: absolute;
    z-index: 100;
  }

  .owl-carousel.thm_sldr,
  .owl-carousel.thm_sldr .owl-next {
    right: 0px !important;
  }

  .owl-carousel.thm_sldr .owl-nav {
    display: none !important;
  }

  .owl-carousel.thm_sldr,
  .thm_hdng_wppr {
    padding: 0px 0px !important;
    align-items: center;
  }


  .owl-carousel.bnnr-sldr.owl-loaded .owl-dots {
    width: 100% !important;
    left: inherit;
    text-align: center;
  }

  #playercontainer_wrapper {
    z-index: 1000 !important;
  }

  .infinite-scroll-component .contentLoader {
    display: none !important;
  }

  .amrt-lve-popup-wppr .amrt-lve-popup-cntct-wppr .amma-sldr-wppr .amma-sldr .owl-dots .owl-dot {
    width: 5px !important;
    height: 5px !important;
    margin: 2px !important;
  }

  .vdo_sctn_wppr .vdeo-wppr .vdo-txt-wppr .title .vdo-pera-wppr p {
    margin-top: 0px !important;
  }

  .amma-sldr-text-wppr {
    padding-left: 15px !important;
  }

  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr .thmls-itm a .title {
    padding: 0px !important;
  }

  div.dshbrd-prfl-wppr{padding-top: 0px !important;}
  .dshbrd-prfl-wppr .dsbrd-cntct-wppr .acnt-pln .acnt-pln-hdng-wppr .chng-pswd-wppr fieldset .PlcHldrTxt {
    top: 12px;
  }

  .dshbrd-prfl-wppr .dsbrd-cntct-wppr .acnt-pln .acnt-pln-hdng-wppr .chng-pswd-wppr fieldset .FrmTxt:focus~.PlcHldrTxt,
  .dshbrd-prfl-wppr .dsbrd-cntct-wppr .acnt-pln .acnt-pln-hdng-wppr .chng-pswd-wppr fieldset .PlcHldrTxt.Focus {
    top: -8px !important;
  }



  .loader_img {
    width: 115px !important;
  }

  .amma-cntnt-sldr .amma-sldr-wppr .amma-sldr-text-wppr p {
    text-align: left;
  }

  .mylst-wppr .mylst-hdng-wppr .viwhdng,
  .mylst-wppr .mylst-hdng-wppr .mylst-hdng-txt .title h1 {
    padding: 5px;
  }

  .viewmor-wppr,
  .mylst-hdng-wppr .thmmls-wppr {
    padding-left: 7px;
  }

  .abt-pop-wppr .abt-pop .abt-txt p {
    line-height: 24px !important;
  }


  .home_footer{padding: 10px 10px !important;}

  .plans-page.connectedDevicesSection  .part2 h2{margin-bottom: 5px !important;}
  .connectedDevices .connectedDevicesRow .w-75 h3{font-size: 20px;}
  .connectedDevices{overflow: inherit !important;}

  .acdng_tb_wppr{margin-bottom: 25px !important;}
  .MuiBackdrop-root{z-index: 1000000000000000 !important;}


  .premiumTag {
    right: 7px !important;
    width: 15% !important;
}


.searchResult img.placeholderImg{top: 0px !important;}
section.connectedDevicesSection{overflow: inherit !important; height: auto !important; padding-bottom: 40px !important;}
.app-section.ml-2{margin-left: 0px !important;}


}


@media (min-width: 767px) and (max-width:992px) {
  .search_page .item {
    width: 23% !important;
  }

  .pymt-plan-wppr .col-md-4 {
    display: inline-block !important;
    width: 350px;
    vertical-align: top;
  }

  .thm_sldr .slick-slide {
    width: 30% !important;
  }

  .thumb_slider .slick-track .slick-slide {
    width: 220px !important;
  }

  .thmmls-wppr.searchResult .col-12,
  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr.searchResult .col-12 {
    flex: 0 0 30.7% !important;
    max-width: 30.7% !important;
    display: inline-block !important;
  }

  div.dshbrd-prfl-wppr{padding-top: 10px !important; padding-bottom: 40px !important;}

  .dshbrd-prfl-wppr .dsbrd-cntct-wppr{width: 90% !important;}
  .dshbrd-prfl-wppr .dsbrd-cntct-wppr .usrprl-pdng{padding: 30px !important;}
  .acnt-pln .col-md-8{max-width: 100% !important; flex: 1 !important;}

}

@media (min-width:992px) and (max-width:1200px) {
  .search_page .item {
    width: 25% !important;
  }
}

@media (min-width: 240px) and (max-width: 992px) {
  .res-full {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }

  header.header {
    background: #1a1a1a !important;
    position: relative !important;
    z-index: 100000000 !important;
  }
  .Hdr-mnu-wppr {
    padding: 10px 10px !important;
  }

  .top-hdr-wppr .Hdr-mnu-wppr .login-wppr{padding-right: 10px !important;}
  .container2{padding: 0px 10px !important;}
  .owl-carousel.bnnr-sldr.owl-loaded .owl-nav{display: none !important;}

  .carouselWrapper {
    height: 100% !important;
  }

  .login_wrapper {
    padding: 25px
  }

  .login_wrapper h3 {
    font-size: 25px;
  }

  .otp_form {
    width: 100%;
    margin: auto;
  }

  .otp_form div.d-flex {
    justify-content: center;
  }

  .otp_form input {
    width: 60px;
  }

  .container2,
  .container {
    width: 100% !important;
    padding: 0 15px
  }

  .infinite-scroll-component .pt-4 {
    padding-top: 5px !important;
  }

  .owl-carousel.thm_sldr.owl-loaded.owl-drag .owl-stage-outer .owl-stage {
    padding: 0px 0px;
  }

  .vdo_sctn_wppr .vdeo-wppr {
    flex-wrap: inherit !important;
  }

  div.vdo-tbng-sldr-wppr .thm_slr_bx {
    display: block !important;
    overflow-x: scroll !important;
    white-space: nowrap !important;
    padding: 0px !important;
    margin-bottom: 25px !important;
  }

  .epsd-slctr {
    margin-right: 10px !important;
  }

  .vdo-tbng-sldr-wppr .thm_slr_bx::-webkit-scrollbar {
    display: none !important;
  }

  .placeholder {
    min-height: auto !important;
    height: auto !important;
  }

  .thm_slr_bx {
    padding: 5px 0;
  }

  header {
    background: #000;
    position: relative;
  }

  /*.mainWrapper{margin-top: 60px}*/
  header.drk-hdr {
    position: fixed !important;
  }

  body.topspace {
    padding-top: 0px !important;
  }

  .amma-cntnt-sldr {
    margin-top: -1px;
  }

  .owl-carousel.thm_sldr,
  .thm_hdng_wppr {
    padding: 0px
  }

  .vdo-txt-wppr {
    padding-bottom: 15px;
  }

  .col-xl-6.part2.res-full.box {
    justify-content: space-between !important
  }

  .jw-flag-small-player .jw-settings-menu {
    max-width: min-content !important;
    width: auto !important;
    height: auto !important;
  }

  .jw-flag-touch .jw-settings-submenu {
    overflow-y: auto !important;
  }

  .jw-settings-content-item {
    padding: 0px 0 3px 15px !important
  }

  .jw-icon-inline,
  .jw-icon-tooltip {
    height: 33px !important;
  }

  .videoplayerwrapper {
    position: static !important;
    padding-bottom: 0% !important;
  }

  .thm_hdng_wppr h3 {
    margin-bottom: 0px !important;
  }


  .owl-carousel.bnnr-sldr.owl-loaded .vido-wppr {
    height: auto !important;
  }

  .thmmls-wppr {
    display: flex !important;
    flex-wrap: wrap;
  }

  .vdo_sctn_wppr .vdeo-wppr .crt-btn-wppr {
    justify-content: flex-start !important;
  }

  .bnnr-sldr .placeholder {
    height: 100% !important;
  }

  .owl-carousel.bnnr-sldr.owl-loaded .vido-wppr {
    height: inherit !important;
  }

  .tabbing-wppr {
    margin-bottom: 15px;
  }

  .tabbing-wppr .tbng-wppr-bx header .MuiTabs-root {
    width: 100% !important;
  }

  .vdo_sctn_wppr{position: relative; z-index: 10000000 !important;}
  .tabbing-wppr .tbng-wppr-bx header .MuiTabs-root::-webkit-scrollbar{display: none !important;}


  .pymt-plan-wppr {
    display: block !important;
    white-space: nowrap;
    overflow-x: scroll;
  }

  .pymt-plan-wppr::-webkit-scrollbar {
    display: none !important;
  }

  .pymt-pn-lst-wppr {
    flex-direction: column !important;
    margin-top: 15px !important;
  }

  div.infinite-scroll-component {
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }


  .infinite-scroll-component .contentLoader {
    position: fixed !important;
  }

  .vdo-popbx div.videoplayerwrapper video {
    height: 100% !important;
  }

  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr .thmls-itm a,
  .owl-carousel.thm_sldr.owl-loaded.owl-drag .owl-stage-outer .owl-stage .owl-item {
    transform: scale(1) !important;
  }

  .owl-carousel.thm_sldr.owl-loaded.owl-drag .owl-stage-outer .owl-stage .owl-item:hover {
    transform: scale(1) !important
  }

  .thm_sldr_itm:hover,
  .owl-item:hover .toppick-txt-wppr {
    box-shadow: none !important;
  }

  .mylst-wppr .viewmor-wppr .infinite-scroll-component__outerdiv .infinite-scroll-component .thmmls-wppr {
    margin: 0px !important;
  }

  .owl-carousel.thm_sldr.owl-loaded.owl-drag .owl-stage-outer .owl-stage .owl-item {
    transition: all 0s !important;
  }

  .faq-tbng .MuiPaper-root .MuiCollapse-root .MuiCollapse-wrapper a,
  .acdng_tb_wppr .title a {
    word-break: break-all;
  }

  .vdo-txt-wppr .vdo-pera-wppr {
    height: auto !important;
    max-height: 250px;
  }

  .owl-carousel.bnnr-sldr .owl-item.active .vido-wppr {
    animation: none !important;
  }

  .thmls-itm .removeFromMylist {
    opacity: 1 !important;
    visibility: visible !important;
    top: 10px !important;
  }

  .thumb_slider {
    padding: 0px !important;
  }

  .thumb_slider .slick-next,
  .thumb_slider .slick-prev {
    display: none !important;
  }

  .zoomContainer {
    display: none !important;
  }

  .thm_hdng_wppr h3 {
    width: 100% !important;
  }

  .thm_hdng_wppr .viewAllBtn {
    opacity: 1 !important;
  }

  .viewAllBtn span {
    width: 70px !important;
    opacity: 1 !important;
  }

  .viewAllBtn i {
    margin-left: 5px !important;
  }

  .thm_hdng_wppr a {
    margin-left: auto !important;
  }

  .connectedDevices{padding: 0px 30px !important;}
  

}



@media (min-width: 2000px) and (max-width: 4000px) {

  .top-hdr-wppr .container,
  .container2,
  .home_footer .container {
    width: 90% !important;
    margin: auto;
  }

  .thmls-itm a img {
    height: 18vh !important;
  }

  .amma-cntnt-sldr .amma-sldr-wppr .amma-sldr-text-wppr p {
    font-size: 20px !important;
    line-height: 29px !important;
  }

  .carouselWrapper {
    min-height: 70vh;
    overflow: hidden;
  }

  .owl-carousel.bnnr-sldr.owl-loaded .vido-wppr {
    height: auto !important;
  }


  .amma-cntnt-sldr {
    margin-top: -15px !important;
  }

  .vdo-txt-wppr .vdo-pera-wppr {
    height: 52vh;

  }

  .placeholder.hor,
  .placeholder.vert {
    border-radius: 15px !important;
  }


}

@media (min-width: 1300px) and (max-width:2000px) {
  .dsbrd-hdng-wppr {
    padding: 25px !important;
  }

  /* .thmls-itm a img{height: 20vh !important;} */
}

@media (min-width: 2300px) and (max-width: 3000px) {

  /* header{zoom: 120%;} */
  body,
  input,
  textarea,
  button,
  p,
  a,
  li,
  select,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .thm_hdng_wppr h3,
  .faq-tbng .MuiPaper-root .MuiCollapse-root .MuiCollapse-wrapper .MuiCollapse-wrapperInner .MuiAccordionDetails-root p,
  .acdng_tb_wppr .title p {
    font-size: 21px;
    line-height: 30px;
  }

  .home_footer .links li a {
    font-size: 22px;
    line-height: 37px;
  }

  .desc-main,
  .desc-main p {
    font-size: 18px;
    font-weight: 18px !important
  }

  .faq-tbng .MuiPaper-root .MuiAccordionSummary-root .MuiAccordionSummary-content p {
    font-size: 27px !important;
  }

  .faq_bg .title {
    font-size: 32px;
  }

  .home_footer .social ul li a {
    width: 35px;
    height: 35px;
  }

  .home_footer .app-section ul li a .icn-txt p {
    font-size: 16px;
  }

  .prmum-tag::after,
  .prmum-txt::after {
    top: -12px;
    left: -1px;
  }

  .acdng_hdng_wppr .title h4 {
    font-size: 20px;
  }

  .acdng_tb_wppr .title p,
  .acdng_hdng_wppr .title ul li {
    font-size: 16px;
    line-height: 20px
  }
}

@media (min-width: 3000px) and (max-width: 4000px) {
  header {
    zoom: 190%;
  }

  .owl-carousel.bnnr-sldr.owl-loaded .owl-nav button {
    font-size: 14rem !important;
  }

  body,
  input,
  textarea,
  button,
  p,
  a,
  li,
  select,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .thm_hdng_wppr h3,
  .faq-tbng .MuiPaper-root .MuiCollapse-root .MuiCollapse-wrapper .MuiCollapse-wrapperInner .MuiAccordionDetails-root p,
  .acdng_tb_wppr .title p {
    font-size: 35px;
    line-height: 55px;
  }

  .desc-main,
  .desc-main p {
    font-size: 18px;
    font-weight: 18px !important
  }

  .faq-tbng .MuiPaper-root .MuiAccordionSummary-root .MuiAccordionSummary-content p {
    font-size: 32px !important;
  }

  .home_footer .social ul li a {
    width: 45px;
    height: 45px;
  }

  .home_footer .app-section ul li a .icn-txt p {
    font-size: 20px;
  }

  .prmum-tag::after,
  .prmum-txt::after {
    top: -12px;
    left: -1px;
  }
}


/*------Payment plans page code start here-----------*/
@media (min-width: 240px) and (max-width:575px) {
  .plans-page .part1 ul li span {
    font-size: 13px !important;
    font-weight: 500 !important;
  }

  .plans-page .part1 ul li svg {
    font-size: 13px !important;
    top: 4px !important;
  }
}

@media (min-width: 240px) and (max-width:1100px) {
  .plans-page {
    overflow: scroll !important;
    overflow-x: hidden !important;
  }

  .plans-page .part1 {
    padding: 30px !important;
    height: auto !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }

  .plans-page .part2 {
    max-width: 100% !important;
    flex: 0 0 100% !important;
    background: #f1f1f1 !important;
  }

  .plans-page .part1 img {
    width: 100px;
    margin-bottom: 20px;
  }

  .plans-page .part1 div.ml-auto {
    margin-top: 0px !important;
    display: flex;
    flex-direction: row !important;
  }

  .plans-page .part1 h1 {
    font-weight: 500 !important;
    font-size: 7vw;
    order: 2;
    line-height: 40px;
  }

  .plans-page .part1 .arrowIcon {
    width: 100px !important;
    margin-bottom: 0px !important;
  }

  .plans-page .part1 .order1 {
    order: 1;
    padding-left: 30px;
  }

  .plans-page .part1 ul li {
    text-align: right;
  }

  .plans-page .part1 ul li svg {
    margin-left: auto;
    order: 2;
    margin-left: 15px;
  }


  .plans-page .part2 {
    padding:30px 0px !important;
    padding-bottom: 50px !important;
  }

  .plans-page .part2 {
    height: auto !important;
  }

  .plans-page .part2 .box {
    padding: 0px 0px;
  }

  .plans-page .part2 h2 {
    font-size: 24px !important;
  }

  .plansListWrapper {
    box-shadow: none !important;
  }

  .plans-page .part2 .plansList {
    height: auto !important;
    display: block !important;
    background: none !important;
    box-shadow: none !important;
    padding-bottom: 20px;
    border: 0px !important;
  }

  .plans-page .part2 .plansList .col1,
  .plans-page .part2 .plansList .col2,
  .plans-page .part2 .plansList .col3,
  .plans-page .part2 .plansList .col4,
  .plans-page .part2 .plansList .col2 .col_1,
  .plans-page .part2 .plansList .col2 .col_2 {
    height: auto !important;
  }

  .plans-page .part2 .plansList .col2 .col_1 {
    box-shadow: none !important;
  }

  .plans-page .part2 .plansList .col2 .col_1 h5 {
    font-size: 18px !important;
  }

  .plans-page .part2 .box {
    padding: 0px 0px !important;
  }

  .plans-page .part2 .plansList .col1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 136px !important;
    background: #9d9d9d;
    padding: 5px 25px !important;
  }

  .plans-page .part2 .plansList .col1 h4 {
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 500 !important;
  }

  .plans-page .part2 .plansList .col2 {
    width: 90%;
    height: 50px !important;
    background: #fff;
    margin-left: auto;
  }

  .plans-page .part2 .plansList .col2 .col_1 {
    position: static;
  }

  .plans-page .part2 .plansList .col2 .offerDiv .w-75 {
    position: absolute !important;
    top: 0px;
    background: #d69231;
    height: 47px;
    left: 110px;
    width: 100% !important;
  }

  .plans-page .part2 .plansList.act .col2 .col_1::before {
    display: none !important;
  }

  .plans-page .part2 .plansList .col2 .col_1 span {
    font-size: 16px;
  }

  .plans-page .part2 .plansList .col2 .col_1 h5 {
    font-size: 25px;
  }

  .plansListWrapper {
    max-height: inherit !important;
    overflow: inherit !important;
  }

  .plans-page .part2 .plansList .col2 span.span_ {
    font-size: 16px;
  }


  .plans-page .part2 .plansList .col2 span.span_::before {
    top: 4px !important;
  }

  .plans-page .part2 .plansList .col2 .w-25 i {
    font-size: 23px !important;
  }

  .plansListWrapper {
    margin-bottom: 10px !important;
  }

  .plans-page .part2 h2 {
    margin-bottom: 20px !important;
  }

  .plans-page .part1 ul li{justify-content: right !important;}

}

@media (min-width: 240px) and (max-width:575px) {

  .plans-page .part2 .plansList .col2 .col_1 span,
  .plans-page .part2 .plansList .col2 .col_1 h5 {
    zoom: 68% !important;
  }

  .plans-page .part2 .box p {
    font-size: 12px !important;
  }

  .plans-page .btn-lg {
    font-size: 13px !important;
    padding: 0px 20px !important;
  }
}

@media (min-width: 767px) and (max-width:1100px) {
  .plans-page .part2 .box {
    width: 80% !important;
  }
}

@media (min-width: 240px) and (max-width:1000px) {
  .plans-page {
    overflow: scroll !important;
  }

  .plans-page .part1 {
    padding: 30px !important;
  }

  .plans-page .part2 .plansList .col2 .col_1::after {
    background-image: linear-gradient(100deg, #fff, #fff);
  }

  .titleHd {
    font-size: 26px !important;
  }
}


@media (min-width:240px) and (max-width: 1200px) {
  .res-hide {
    display: none !important;
  }

  .offerDiv {
    position: relative !important;
  }

  .plans-page .part2 .plansList .col2 {
    overflow: initial !important;
  }

  .plans-page .part2 .plansList .col2 .offerDiv .w-75 {
    position: absolute !important;
    top: -61px;
    background: #d69231;
    height: 45px;
    left: 99px;
    width: 225px !important;
    zoom: 80%;
  }

  .btnWrapper__ {
    margin-bottom: 30px !important;
    padding-top: 30px !important;
  }

  .plans-page .part2 .plansList .col2 span.span_ {
    white-space: nowrap !important;
    padding-right: 10px !important;
  }

  .plans-page .part2 .plansList .col2 .col_1::after {
    display: none !important;
  }

  .plans-page .part2 .plansList .col2 .col_1 span {
    font-size: 14PX !important;
  }

  .plans-page .btn-lg {
    line-height: 22px !important;
    margin: 0px auto !important;
  }

  .checkoutForm{margin-bottom: 30px !important;}

  .plans-page .login-card {
    width: 85%;
  }

  .plans-page .part2 .plansList .col2 .col_1::before {
    background-image: none !important;
  }

  .plans-page .part2 .plansList .col1::before {
    display: none !important;
  }

  .currentplan .col2::before {
    content: '';
    width: 100px !important;
    height: 30px !important;
    position: absolute !important;
    top: 0px;
    left: 0px;
    z-index: 100;
    background: url(../images/currentplan.png) no-repeat top center;
    background-size: contain !important;
  }


  .invoice {
    padding: 20px 15px !important;
  }

  .invoice * {
    font-size: 13px !important;
  }


}


@media (min-width:240px) and (max-width: 340px) {

  .plans-page .part2 .plansList .col2 .offerDiv .w-25 {
    max-width: 27% !important;
    flex: 0 0 27% !important;
  }

  .plans-page .part2 .plansList .col2 .offerDiv .w-75 {
    position: absolute !important;
    top: -61px;
    background: #d69231;
    height: 45px;
    left: 114px;
    width: 215px !important;
    zoom: 80%;
  }


}

@media (min-width:575px) and (max-width:650px) {
  .plans-page .part2 .plansList .col2 .offerDiv .w-75 {
    left: 90px !important;
  }
}


@media (min-width:1300px) and (max-width:1500px) {
  .plans-page {
    zoom: 90%;
    height: 112vh;
  }

  .plans-page .part1 {
    height: 112vh;
  }

  .btnWrapper__ {
    padding-top: 0px !important;
  }

  .plans-page .part2 {
    padding: 27px 56px !important;
  }

  .plans-page .part1 .arrowIcon {
    width: 143px;
  }

  .plans-page .part2 .box {
    width: 100%;
    margin: auto;
    padding: 37px 26px;
  }
}

/*laptop 125% responsive css start*/
@media (min-width:1500px) and (max-width:1600px) {
  .plans-page .part1 {
    padding: 35px 26px !important;
  }

  .plans-page .part1 .arrowIcon {
    width: 160px !important;
  }

  .plans-page .part2 {
    padding: 2px 100px !important;
  }

  .plans-page .part2 .box p {
    font-size: 16px;
  }

  .plans-page .part2 .box {
    padding: 5px 20px!important;
  }

  .plans-page .part1 h1 {
    font-size: 3.0vw !important;
    line-height: 55px !important;
  }

  .btnWrapper__ {
    padding-top: 10px !important;
  }
  .connectedDevices .connectedDevicesRow{padding: 25px 130px !important;}
}
/*laptop 125% responsive css end*/

/* multiple  devices logout listing start*/
@media (min-width:240px) and (max-width: 992px) {
  .connectedDevicesSection::-webkit-scrollbar{ height: 0px !important;}
  .connectedDevices .connectedDevicesRow{padding: 25px 0px !important;}    
  .dshbrd-prfl-wppr .gobck-wppr {
    top: 4px !important;
    left: 12px !important;
    width: 15px;
    height: 15px;
}


body, html, div#root, .mainWrapper{min-height: 80vh !important;}

}

@media (min-width:992px) and (max-width: 1400px) {
  .connectedDevices .connectedDevicesRow{padding: 25px 70px !important;}
  .thmmls-wppr.searchResult .col-12,
  .mylst-wppr .mylst-hdng-wppr .thmmls-wppr.searchResult .col-12,.mylst-wppr .mylst-hdng-wppr .thmmls-wppr .col-12 {
    flex: 0 0 23% !important;
    max-width: 23% !important;
    display: inline-block !important;
  }
}
@media (min-width:240px) and (max-width: 767px) {
  .connectedDevices .connectedDevicesRow .w-75{padding-bottom: 20px;}
  .connectedDevices .connectedDevicesRow .w-75 h3{font-size: 20px;}
  .connectedDevices .connectedDevicesRow .w-75, .connectedDevices .connectedDevicesRow .w-25{max-width: 100% !important; width: 100% !important;}
  .connectedDevices .connectedDevicesRow .box__{flex-direction: column;}

  .pln-hstry-wppr .title{flex-direction: column; width: 100%;}
  .pln-hstry-wppr .title div{width: 100%;}
  .pln-hstry-wppr .title button{margin-right: auto; width: max-content; padding: 5px 10px !important; zoom: 80%;}
  .dshbrd-prfl-wppr .dsbrd-cntct-wppr .acnt-pln .bling-tbl-wppr table tr td{font-size: 12px !important;}
  .dshbrd-prfl-wppr .dsbrd-cntct-wppr .acnt-pln .bling-tbl-wppr table *{white-space: nowrap;}
  .dshbrd-prfl-wppr .dsbrd-cntct-wppr .acnt-pln .bling-tbl-wppr table th{text-align: left !important;}

  .otp_form {width: 100% !important;}

 
}

/* multiple  devices logout listing end*/

/*------Payment plans page code ends here-----------*/