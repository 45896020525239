.staticpage{width: 100% !important; height: auto; padding: 30px; background: #f3f3f3;}
.staticpage h2{font-size: 24px !important; font-weight: 600 !important;}
.staticpage h3{font-size: 20px !important; font-weight: 600 !important; margin-bottom: 0px !important;}
.staticpage p{ font-weight: 400 !important; font-size: 14px !important; line-height: 20px !important;}

.staticpage ul{margin-bottom: 10px !important;}
.staticpage ul li{ list-style-type:none !important; padding-left: 10px !important; margin-bottom: 5px !important; font-size: 14px !important;}
.staticpage ul li a{font-weight: 600 !important;}


/* .staticpage ol{margin-bottom: 35px !important;}
.staticpage ol li{ list-style-type:disc !important; padding-left: 10px !important; margin-bottom: 15px !important; font-size: 16px !important;}
.staticpage ol li a{font-weight: 600 !important;} */