
.top_div{
-webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
   -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
     -o-animation: fadein 2s; /* Opera < 12.1 */
        animation: fadein 2s;
      }
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


.load_place_vert{
  background:url('/images/vertical@3x.jpg');
  background-repeat:no-repeat;
  background-position:center;
  background-size: cover;
}
.load_place_horiz{
  background:url('/images/horizontal@3x.jpg');
  background-repeat:no-repeat;
  background-position:center;
  background-size: cover;
}
.load_place_horiz_cat{
  background:url('/images/horizontal@3x.jpg');
  background-repeat:no-repeat;
  background-position:center;
  background-size: cover;
  /* height:140px;
  width:240px; */
  /* height: 250px; */
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.load_place_horiz_search{
  background:url('/images/horizontal@3x.jpg');
  background-repeat:no-repeat;
  background-position:center;
  background-size: cover;
  height:140px;
  width:240px
}
.ReactModal__Overlay {
    z-index: 999;
}
.login_load {
    min-width: 300px;
    text-align: center;
}


/*------------toast css------------*/
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}



.animate {
  animation-duration: 800ms;
}
.Toastify__toast-body{
  color:black
}

.close_modal_btn {
  background-color: #f77727 !important;
  opacity: 1 !important;
  color: #fff !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  position: absolute;
  right: -25px;
  top: -25px;
}


/*--------------UPLOAD-------------------*/

.avatar-upload {
    position: relative;
    max-width: 205px;
    margin: 50px auto;
    .avatar-edit {
        position: absolute;
        right: 12px;
        z-index: 1;
        top: 10px;
        input {
            display: none;
            + label {
                display: inline-block;
                width: 34px;
                height: 34px;
                margin-bottom: 0;
                border-radius: 100%;
                background: #FFFFFF;
                border: 1px solid transparent;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                cursor: pointer;
                font-weight: normal;
                transition: all .2s ease-in-out;
                &:hover {
                    background: #f1f1f1;
                    border-color: #d6d6d6;
                }
                &:after {
                    content: "\f040";
                    font-family: 'FontAwesome';
                    color: #757575;
                    position: absolute;
                    top: 10px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: auto;
                }
            }
        }
    }
    .avatar-preview {
        width: 192px;
        height: 192px;
        position: relative;
        border-radius: 100%;
        border: 6px solid #F8F8F8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        > div {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}
